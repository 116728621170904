



















import { Component, Prop, Vue } from 'vue-property-decorator';
import ProblemFilled from '@/assets/icons/ProblemFilled.vue';

const TEXT_DEFAULT = 'Algo deu errado! Não foi possível carregar esse conteúdo. Tente novamente.';
const TEXT_OPS = 'Oops!';

@Component({
  components: {
    ProblemFilled
  }
})
export default class ErrorLoading extends Vue {
  @Prop({ default: TEXT_DEFAULT }) textErro!: string;
  @Prop({ default: false }) showOps!: boolean;

  private textOops = TEXT_OPS;

  handleTryAgain() {
    window.location.reload();
  }
}
